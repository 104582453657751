import React, { useEffect, useState } from 'react';
import type { Control } from 'react-hook-form/dist/types/form';
import type { ICase, IUserSelectOption } from 'app/mobxStore/types';
import { FORM_FIELDS_ENUM, type IFormData } from './types';
import UserAvatarGroup from 'app/components/userAvatarGroup/UserAvatarGroup';
import { useController } from 'react-hook-form';
import { useRootStore } from '../../mobxStore';
import { getClickedSeenTooltip } from '../../mobxStore/storage';
import FollowerChips from 'app/components/followerChips/FollowerChips';
import FollowersSelectPanel from 'app/components/followersSelectPanel/FollowersSelectPanel';

interface IProps {
  control: Control<IFormData>;
  options: IUserSelectOption[];
  isReadOnly?: boolean;
}

const FollowersSelect = (props: IProps): React.JSX.Element => {
  const { isReadOnly, options, control } = props;
  const [optionToShowTooltip, setOptionToShowTooltip] = useState<IUserSelectOption | null>(null);
  const {
    userStore,
    caseStore: { openedCase }
  } = useRootStore();

  if (
    options.length === 0 ||
    (options.length === 1 && options[0].userId === userStore.loggedInUser.data.id)
  ) {
    return <></>;
  }

  const {
    field: { value, onChange }
  } = useController({
    name: FORM_FIELDS_ENUM.FOLLOWERS,
    control
  });

  const getGroupByIsSeenHuddle = (option: IUserSelectOption): number => {
    if (openedCase?.basicCase.getUserLastSeen(option.userId)) {
      return 1;
    }
    return 0;
  };

  const sortOptions = (a: IUserSelectOption, b: IUserSelectOption): number => {
    const aGroup = getGroupByIsSeenHuddle(a);
    const bGroup = getGroupByIsSeenHuddle(b);
    return bGroup - aGroup;
  };

  const [followers, setFollower] = useState<IUserSelectOption[]>([...value]);
  const [groupedFollowers, setGroupedFollowers] = useState<IUserSelectOption[]>([...value]);
  const [fullFollowers, setFullFollowers] = useState<IUserSelectOption[]>([...value]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const maxFollowersCount = 5;

  useEffect(() => {
    const sortedValue = [...value].sort(sortOptions);
    setFollower(sortedValue);
    setGroupedFollowers(sortedValue.slice(maxFollowersCount, sortedValue.length));
    setFullFollowers(sortedValue.slice(0, maxFollowersCount));
    if (sortedValue.length <= maxFollowersCount + 1) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [value]);

  useEffect(() => {
    if (getClickedSeenTooltip()) {
      return;
    }
    const foundOption = value.find(option => openedCase?.basicCase.getUserLastSeen(option.userId));
    if (!foundOption) {
      return;
    }
    setOptionToShowTooltip(foundOption);
  }, []);

  const handleExpand = async (): Promise<void> => {
    if (value.length <= maxFollowersCount + 1) {
      return;
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="followers-select">
      <div className="follower-chips-container" onClick={handleExpand}>
        <FollowerChips
          options={isExpanded ? followers : fullFollowers}
          kase={openedCase?.basicCase as ICase}
          optionToShowTooltip={optionToShowTooltip}
        />
        {!isExpanded && <UserAvatarGroup users={groupedFollowers} maxUsers={10} size="medium" />}
      </div>
      {!isReadOnly && <FollowersSelectPanel options={options} onChange={onChange} />}
    </div>
  );
};

export default FollowersSelect;
