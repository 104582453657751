import React, { useState, useEffect, useRef } from 'react';
import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import {
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  eachWeekOfInterval,
  addYears,
  isSameDay,
  differenceInCalendarDays
} from 'date-fns';
import WeekGroup from './WeekGroup';
import './WeekDayPicker.scss';

import { Swiper, SwiperSlide, type SwiperRef } from 'swiper/react';
import type { Swiper as SwiperTypes } from 'swiper';
import 'swiper/css';

interface Week {
  days: Date[];
  index: number;
}

interface IProps {
  handleClickDay: (day: Date) => void;
}

const WeekDayPicker = (props: IProps): React.JSX.Element => {
  const { handleClickDay } = props;
  const { caseStore } = useRootStore();
  const selectedDay = caseStore.selectedDateFilter;
  const swiperRef = useRef<SwiperRef>(null);
  const initWeeks: Week[] = [];
  const [weeks, setWeeks] = useState(initWeeks);
  const [initSlide, setInitSlide] = useState<number | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isInit, setIsInit] = useState(false);
  const [isSlideInit, setIsSlideInit] = useState(false);
  const getWeekDays = (day: Date): any => {
    return eachDayOfInterval({
      start: startOfWeek(day, { weekStartsOn: 1 }),
      end: endOfWeek(day, { weekStartsOn: 1 })
    });
  };

  // create initial weeks array
  const weeksArr = (): void => {
    const range = eachWeekOfInterval(
      {
        start: addYears(Date.now(), -2),
        end: addYears(Date.now(), 2)
      },
      { weekStartsOn: 1 }
    );
    const startOfWeekOfSelectedDay = startOfWeek(selectedDay, { weekStartsOn: 1 });

    const weeks = range.map((day, i) => {
      if (isSameDay(day, startOfWeekOfSelectedDay)) {
        setInitSlide(i);
      }
      const newWeek: Week = {
        days: getWeekDays(day),
        index: i
      };
      return newWeek;
    });
    setWeeks(weeks);
  };

  const handleSlideChange = (e: SwiperTypes): void => {
    if (!isSlideInit) {
      setIsSlideInit(true);
      return;
    }
    const prevSelectedDayIndex = differenceInCalendarDays(
      selectedDay,
      startOfWeek(selectedDay, { weekStartsOn: 1 })
    );
    const newDay = weeks[e.activeIndex].days[prevSelectedDayIndex];
    handleClickDay(newDay);
  };

  const handleSlideTo = (index: number): void => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideReset(1);
      swiperRef.current.swiper.slideTo(index, 300, false);
    }
  };

  // range of rendered weeks around current week
  const inRange = (x: number, min: number, max: number): boolean => {
    return (x - min) * (x - max) <= 0;
  };

  useEffect(() => {
    if (!isInit) {
      weeksArr();
    }

    setIsInit(true);
  }, [isInit]);

  // handle change slide when selected day is changed outside of the week picker
  useEffect(() => {
    if (!isInit) {
      return;
    }
    if (!isSameDay(weeks[activeIndex].days[0], startOfWeek(selectedDay, { weekStartsOn: 1 }))) {
      for (let i = 0; i < weeks.length; i++) {
        if (isSameDay(weeks[i].days[0], startOfWeek(selectedDay, { weekStartsOn: 1 }))) {
          setActiveIndex(i);
          handleSlideTo(i);
          break;
        }
      }
    }
  }, [selectedDay]);

  return (
    <div className="week-day-picker">
      {initSlide && (
        <Swiper
          ref={swiperRef}
          initialSlide={initSlide}
          loop={false}
          spaceBetween={10}
          className="slides"
          onSlideChangeTransitionEnd={(e: any) => {
            handleSlideChange(e);
          }}
        >
          {weeks.map(week => (
            <SwiperSlide key={week.index}>
              {inRange(week.index, activeIndex - 1, activeIndex + 1) && (
                <WeekGroup handleClickDay={handleClickDay} weekDays={week.days} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default observer(WeekDayPicker);
