import React, { type MouseEvent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import Button from 'app/components/buttons/Button';
import ArrowLeftIcon from 'app/assets/icons/ArrowLeft_icon';
import ArrowRightIcon from 'app/assets/icons/ArrowRight_icon';
import variables from 'app/utils/variables.module.scss';
import { ReactComponent as PenguinWelcome } from 'app/assets/penguins/penguin_4_animated.svg';
import { ReactComponent as PenguinSkating } from 'app/assets/penguins/penguins_3_in_a_bobsleigh_animated.svg';
import { ReactComponent as PenguinMagicAnimated } from 'app/assets/penguins/penguin_5_animated_happy_magic_penguin.svg';
import ChiefyLogoIcon from 'app/assets/icons/ChiefyLogo_icon';
import './Onboarding.scss';
import { setPassedOnboarding } from '../../mobxStore/storage';
import { useNavigate } from 'react-router-dom';
import ProfileScreen from './ProfileScreen';

const onboardingScreens = [
  {
    name: 'onboarding-1',
    icon: <PenguinWelcome />,
    title: 'onboarding1Title',
    text: 'onboarding1Text'
  },
  {
    name: 'onboarding-2',
    icon: <PenguinSkating />,
    title: 'onboarding2Title',
    text: 'onboarding2Text'
  },
  {
    name: 'onboarding-3',
    icon: <PenguinMagicAnimated />,
    title: 'onboarding3Title',
    text: 'onboarding3Text'
  },
  {
    name: 'profile-screen'
  }
];

const whatsNewScreen = [
  {
    name: 'onboarding-whats-new',
    icon: <PenguinWelcome />,
    title: 'onboardingWhatsNewTitle',
    text: 'onboardingWhatsNewText'
  }
];

const Onboarding = (): React.JSX.Element => {
  const isWhatsNewOnly = false; // If changing to true, need to test the skip button or done button
  const screens = isWhatsNewOnly ? whatsNewScreen : onboardingScreens;
  const [currentScreen, setCurrentScreen] = useState(screens[0]);

  const navigate = useNavigate();
  const handleNavToScreen = (index: number): void => {
    if (index < screens.length) {
      setCurrentScreen(screens[index]);
    }
  };

  const closeOnboarding = (): void => {
    setPassedOnboarding(true);
    navigate('/');
  };

  const handleSkip = (): void => {
    if (currentScreen.name === 'profile-screen') {
      closeOnboarding();
      return;
    }
    handleNavToScreen(onboardingScreens.length - 1);
  };

  return (
    <div className="page-container onboarding">
      <div className="onboarding-page">
        {currentScreen.name !== 'profile-screen' && (
          <div className="onboarding-header">
            <div className="flex">
              <ChiefyLogoIcon className="logo" height={24} />
            </div>
            {!isWhatsNewOnly && (
              <div className="progress flex">
                {screens.map((screen, index) => (
                  <div
                    key={screen.name}
                    className={clsx('bar', { current: currentScreen === screen })}
                  ></div>
                ))}
              </div>
            )}
            <div className="flex">
              <Button
                classNames="btn skip-button"
                buttonText="Skip"
                onClick={async (e: MouseEvent) => {
                  handleSkip();
                }}
              />
            </div>
          </div>
        )}
        {screens.map((screen, index) => {
          return (
            <div
              key={screen.name}
              className={clsx('screen-conainer', {
                current: currentScreen === screen,
                profile: screen.name === 'profile-screen'
              })}
              style={{ zIndex: index }}
            >
              {screen.name === 'profile-screen' ? (
                <ProfileScreen closeOnboarding={closeOnboarding} />
              ) : (
                <>
                  <div className="image">{screen.icon}</div>
                  <div className="screen-content">
                    <div className="title">
                      <Trans i18nKey={screen.title} />
                    </div>
                    <div className="text">
                      <Trans
                        i18nKey={screen.text}
                        components={{ bullet: <span className="bullet" /> }}
                      />
                    </div>
                    {screen.name === 'onboarding-whats-new' && (
                      <div className="text contact">
                        <Trans
                          i18nKey="onboardingWhatsNewContact"
                          components={{
                            contact: (
                              <button
                                onClick={() => {
                                  window.location.assign('mailto:hello@chiefyteam.com');
                                }}
                              />
                            )
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="actions">
                    {index !== 0 && (
                      <Button
                        classNames="btn circle-button back"
                        startIcon={<ArrowLeftIcon height={10} stroke={variables.black} />}
                        onClick={async (e: MouseEvent) => {
                          handleNavToScreen(index - 1);
                        }}
                      />
                    )}
                    {index < screens.length - 1 && (
                      <Button
                        classNames="btn circle-button next"
                        startIcon={<ArrowRightIcon height={10} stroke={variables.white} />}
                        onClick={async (e: MouseEvent) => {
                          handleNavToScreen(index + 1);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(Onboarding);
