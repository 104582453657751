import apiService from 'app/services/apiService';
import type { IHasuraTokenData, ILoggedInUserData } from 'app/mobxStore/types';
import jwtDecode from 'jwt-decode';
import type { ROLES } from 'app/consts';
import { log } from 'debug';
import ErrorMonitor from '../services/errorMonitor/errorMonitor';

export const getLoggedInUserData = async (
  roles: ROLES[]
): Promise<ILoggedInUserData | undefined> => {
  try {
    const user = await apiService.getCurrentUser();
    const userMeta = await apiService.getCurrentUserMetaData();

    return {
      roles: roles.map(r => ({ role: r })),
      nickName: user.nickName,
      avatar: user.avatar,
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      followAll: user.followAll,
      isNurseLeader: user.isNurseLeader,
      phone: userMeta.phone,
      email: userMeta.email,
      isActive: user.isActive,
      vendor: user.vendor,
      reminderDays: userMeta.reminderDays
    };
  } catch (err) {
    ErrorMonitor.captureException(err as Error);
    log(err);
  }
};

const HASURA_KEYS = {
  CLAIMS: 'https://hasura.io/jwt/claims',
  SITES_IDS: 'x-hasura-user-sites-ids',
  ALLOWED_ROLES: 'x-hasura-allowed-roles',
  USER_ID: 'x-hasura-user-id'
};

export const getUserDataFromToken = (token: string | null): IHasuraTokenData => {
  if (token === null || token === '') {
    return {
      userId: '',
      roles: [],
      sitesIds: ''
    };
  }
  const decoded = jwtDecode(token);
  if (decoded === undefined || decoded === null || decoded === '') {
    return {
      userId: '',
      roles: [],
      sitesIds: ''
    };
  }
  // @ts-expect-error need map
  const roles = decoded[HASURA_KEYS.CLAIMS][HASURA_KEYS.ALLOWED_ROLES];
  // @ts-expect-error need map
  const sitesIds = decoded[HASURA_KEYS.CLAIMS][HASURA_KEYS.SITES_IDS];
  // @ts-expect-error need map
  const userId = decoded[HASURA_KEYS.CLAIMS][HASURA_KEYS.USER_ID];

  return {
    userId,
    roles,
    sitesIds
  };
};
