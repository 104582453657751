import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  type NormalizedCacheObject,
  split
} from '@apollo/client';
import type { FetchResult } from '@apollo/client/link/core/types';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { setContext } from '@apollo/client/link/context';
import { type ErrorLink, type ErrorResponse, onError } from '@apollo/client/link/error';
import type { DocumentNode, GraphQLError } from 'graphql';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import API_CONFIG from './apiServiceConfig';
import api from './api';
import { camelizeKeys, decamelizeKeys } from 'utils/humps';
import { getUserDataFromToken } from 'app/apiHelper';
import { processFieldValues } from 'utils/form/PMMFields';
import { ROLES, USER_CASE_ROLES } from 'app/consts';
import {
  CaseStatusEnum,
  type IAuthHeader,
  type ICareTeamDefaults,
  type ICareTeamRawDefaults,
  type ICaseAsset,
  type ICaseBasicData,
  type ICaseByDescriptionData,
  type ICaseFollowerData,
  type ICaseFollowersInput,
  type ICaseFullData,
  type ICasesByProcedureData,
  type ICommentData,
  type ICreateCaseInput,
  type IFieldValuesCount,
  type IFieldValuesCountSum,
  type IFullUserData,
  type IMeta,
  type IOnt,
  type IPMMFields,
  type IRawFieldValuesCount,
  type IServerProcessedField,
  type ISiteData,
  type ISiteRawData,
  type IUploadcareSignature,
  type IUserMetaData
} from 'app/mobxStore/types';
import type { Subscription } from 'zen-observable-ts';
import { toast } from 'react-toastify';
import { addCaseToMap, getCaseDate, isTrue } from '../../mobxStore/storage';
import { toZonedTime } from 'date-fns-tz';
import { isToday, isTomorrow } from '../../mobxStore/timeHelper';
import { isStringSet } from '../helper';
import AUTH_CONFIG from '../auth0Service/auth0ServiceConfig';
import ErrorMonitor from '../errorMonitor/errorMonitor';
import AmplService from '../amplService/amplService';

let accessToken = '';

let accessTokenGetter: (() => Promise<string>) | null = null;
export const setAccessTokenGetter = (tokenGetter: () => Promise<string>): void => {
  accessTokenGetter = tokenGetter;
};

export const setAccessToken = (token: string): void => {
  accessToken = token;
};

export const getAccessToken = (): string => {
  return accessToken;
};
export const commentMetaDatasColumnReaction = 'reaction';
export const commentMetaDatasColumnReadAt = 'read_at';

const isCaseValid = (c: ICaseBasicData): boolean => {
  return c.siteId !== null && c.attendingId !== null;
};
const overrideLite = isTrue(localStorage.getItem('overrideLite') ?? 'false');

export const lite = isTrue(process.env.REACT_APP_NO_HIPAA ?? 'false') || overrideLite;

const injectCaseDate = (data: Record<string, any>): void => {
  const savedCaseDate = getCaseDate(data.id);
  // @todo for surgeon it should always find case date. For non surgeon it'll never find. What do we want to check here?
  // if (!savedCaseDate) {
  //   console.error('case date is undefined');
  //   // comment out this line in prod
  //   // throw new Error('case date is undefined');
  //   return;
  // }
  data.caseDateStr = savedCaseDate;
};

const overrideCaseDate = (data: Record<string, any>, timezone: string): void => {
  data.caseDate = toZonedTime(new Date(data.caseDateStr), timezone);
};

const parseResult = (res: FetchResult<any>, parseResults: boolean): any => {
  if (res.errors && res.errors.length > 0) {
    console.log(res.errors);
    ErrorMonitor.captureException(new Error('parseResult api error'), {
      extra: JSON.stringify(res.errors)
    });
    return undefined;
  }
  if (res.data === undefined) {
    console.log('no data in response');
    ErrorMonitor.captureException(new Error('no data in response'));
    return undefined;
  }
  return parseResults ? camelizeKeys(res.data) : res.data;
};

const addIdFromToken = (
  values: Record<string, any>,
  fields: string[] = ['createdById']
): Record<string, any> => {
  const data = getUserDataFromToken(accessToken);
  const id = data.userId;
  const idFields: Record<string, any> = {};
  for (const f of fields) {
    idFields[f] = id;
  }
  return {
    ...values,
    ...idFields
  };
};

const getRoleFromOpAndRoles = (opName: string, roles: ROLES[]): ROLES => {
  if (roles.length === 0) {
    return ROLES.GUEST;
  }
  if (opName.startsWith('Inviter')) {
    return ROLES.INVITER;
  }
  if (opName.startsWith('CurrentUser')) {
    return ROLES.CURRENT_USER;
  }
  return ROLES.CARE_TEAM;
};

const getAuthHeaders = (opName: string): IAuthHeader => {
  const hasToken = accessToken !== '';
  if (!hasToken) {
    ErrorMonitor.addBreadcrumb({
      category: 'apiCall',
      message: `try to call to ${opName} with no token`,
      level: 'info'
    });
    return {
      'x-hasura-role': ROLES.GUEST,
      authorization: ''
    };
  }
  const data = getUserDataFromToken(accessToken);
  const userRoles = data.roles;
  const role = getRoleFromOpAndRoles(opName, userRoles);

  ErrorMonitor.addBreadcrumb({
    category: 'apiCall',
    message: `try to call to ${opName}`,
    level: 'info',
    data: {
      role,
      userRoles
    }
  });
  return {
    'x-hasura-role': role,
    authorization: hasToken ? `Bearer ${getAccessToken()}` : ''
  };
};

export const redirectToLogin = (): void => {
  const search = window.location.search === '' ? '?' : window.location.search;
  AmplService.sendEvent(AmplService.EVENTS.TRACE_REDIRECTING_TO_LOGIN);
  window.location.assign(
    window.location.pathname !== '/logout'
      ? `/login${search}&redirectTo=${window.location.pathname}`
      : '/login'
  );
};

const errorLink = onError((error: ErrorResponse) => {
  if (error.graphQLErrors) {
    console.log('error', error);
    error.graphQLErrors.forEach((e: GraphQLError) => {
      console.log(
        `[GraphQL error]: Message: ${e.message}, Location: ${
          e.locations ? e.locations.toString() : 'NONE'
        }, Path: ${e.path ? e.path.toString() : 'NONE'}, OrgError: ${
          e.originalError ? e.originalError.toString() : 'NONE'
        }`
      );
      if (e.message.includes('JWT')) {
        redirectToLogin();
      } else {
        toast.error(`Sorry, that didn't work... 🤷. Please try again.`);
      }
    });
  }
  if (error.networkError) {
    console.log(`[Network error]: ${error.networkError ? error.networkError.message : 'NONE'}`);
  }
});

const validateKaseStatus = (kase: ICaseBasicData, tz: string): void => {
  if (!lite) {
    return;
  }

  // if case was created less then a minute ago, return, because status may have not been updated yet
  if (new Date().getTime() - new Date(kase.createdAt).getTime() < 60000) {
    return;
  }

  if (isToday(kase.caseDate, tz)) {
    if (kase.status === CaseStatusEnum.CURRENT) {
      return;
    }
    console.log('case status is not current. caseId:', kase.id, 'status:', kase.status);
    ErrorMonitor.captureException(new Error('case status is not current'));
    return;
  }
  if (isTomorrow(kase.caseDate, tz)) {
    if (kase.status === CaseStatusEnum.UPCOMING) {
      return;
    }
    console.log('case status is not upcoming. caseId:', kase.id, 'status:', kase.status);
    ErrorMonitor.captureException(new Error('case status is not upcoming'));
    return;
  }

  if (!isStringSet(kase.status)) {
    return;
  }
  console.log('case status is not empty. caseId:', kase.id, 'status:', kase.status);
  ErrorMonitor.captureException(new Error('case status is not empty'));
};

export class ApiService {
  client: ApolloClient<NormalizedCacheObject> | undefined;
  timezone: string = '';
  needTimezoneOverride: boolean = false;
  users: IFullUserData[] = [];
  sites: ISiteData[] = [];
  setUsers = (users: IFullUserData[]): void => {
    this.users = users;
  };

  setSites = (sites: ISiteData[]): void => {
    this.sites = sites;
  };

  setTimeZone = (tz: string): void => {
    this.timezone = tz;
  };

  setNeedTimezoneOverride = (needTimezoneOverride: boolean): void => {
    this.needTimezoneOverride = needTimezoneOverride;
  };

  // processCases = (cases: ICaseFullData[]): ICaseData[] => {
  //   const processedCases = cases.filter(isCaseValid).map(c => {
  //     return this.parseCase(c);
  //   });
  //   if (cases.length !== processedCases.length) {
  //     ErrorMonitor.captureException(new Error('not valid case in cases list'));
  //   }
  //   return processedCases;
  // };

  processFullCases = (cases: ICaseFullData[]): ICaseFullData[] => {
    const processedCases = cases.filter(isCaseValid).map(c => {
      return this.parseFullCase(c);
    });
    if (cases.length !== processedCases.length) {
      ErrorMonitor.captureException(new Error('not valid case in cases list'));
    }
    return processedCases;
  };

  processBasicCases = (cases: ICaseBasicData[]): ICaseBasicData[] => {
    const processedCases = cases.filter(isCaseValid).map(c => {
      return this.parseBasicCase(c);
    });
    if (cases.length !== processedCases.length) {
      ErrorMonitor.captureException(new Error('not valid case in cases list'));
    }
    return processedCases;
  };

  missingUser = false;

  injectEnums = (caseData: ICaseBasicData): ICaseBasicData => {
    if (caseData.attendingId) {
      caseData.attending = this.users.find(u => u.id === caseData.attendingId) ?? null;
    }
    if (caseData.residentId) {
      caseData.resident = this.users.find(u => u.id === caseData.residentId) ?? null;
    }
    const site = this.sites.find(s => s.id === caseData.siteId);
    if (site) {
      caseData.site = site;
    }
    caseData.caseFollowers = caseData.caseFollowers
      .map(f => {
        const user = this.users.find(u => u.id === f.userId) as IFullUserData;
        if (!user) {
          this.missingUser = true;
          return null;
        }
        return {
          ...f,
          user
        };
      })
      .filter(f => f !== null) as ICaseFollowerData[];
    return caseData;
  };

  parseBasicCase = (rawCaseData: ICaseBasicData): ICaseBasicData => {
    const map: Record<string, any> = { ...rawCaseData };

    map.caseDateStr = rawCaseData.caseDate;
    if (lite) {
      injectCaseDate(map);
    }
    overrideCaseDate(map, this.timezone);
    // if kase.caseDate is valid, then validate status
    if (map.caseDateStr) {
      validateKaseStatus(map as ICaseBasicData, this.timezone);
    }
    this.injectEnums(map as ICaseBasicData);
    map.lastValueUpdate = map.caseFieldValues.length
      ? new Date(map.caseFieldValues[0].createdAt)
      : null;
    return map as ICaseBasicData;
  };

  parseFullCase = (rawCaseData: ICaseFullData): ICaseFullData => {
    const map: Record<string, any> = this.parseBasicCase({ ...rawCaseData });

    map.fieldValues = processFieldValues(rawCaseData);
    return map as ICaseFullData;
  };

  // parseCase = (rawCaseData: ICaseFullData): ICaseData => {
  //   const map: Record<string, any> = { ...rawCaseData };
  //
  //   map.fieldValues = {};
  //   map.caseDateStr = rawCaseData.caseDate;
  //   if (lite) {
  //     injectCaseDate(map);
  //   }
  //   overrideCaseDate(map, this.timezone);
  //   if (rawCaseData.procedureData) {
  //     map.fieldValues = processFieldValues(rawCaseData);
  //   }
  //
  //   const kase = map as ICaseData;
  //   // if kase.caseDate is valid, then validate status
  //   if (kase.caseDateStr) {
  //     validateKaseStatus(kase, this.timezone);
  //   }
  //
  //   return kase;
  // };

  init = (): void => {
    if (API_CONFIG.graphqlUrl === undefined) {
      throw new Error('API_CONFIG.graphqlUrl is undefined');
    }
    const wsLink = new GraphQLWsLink(
      createClient({
        url: `wss://${AUTH_CONFIG.audPrefix}.${API_CONFIG.graphqlUrl}`,
        connectionParams: async () => {
          const headers = getAuthHeaders('CareTeamGetOrCasesSubscription');
          return { ...headers, headers };
        },
        lazy: true
      })
    );

    const authLink = setContext((op, { headers }) => {
      // get the authentication token from local storage if it exists
      // return the headers to the context so httpLink can read them
      if (op.operationName === undefined) {
        throw new Error('op is undefined');
      }
      return {
        headers: {
          ...headers,
          ...getAuthHeaders(op.operationName)
        }
      };
    });

    const httpLink = new HttpLink({
      uri: `https://${AUTH_CONFIG.audPrefix}.${API_CONFIG.graphqlUrl}`
    });
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpLink
    );
    this.client = new ApolloClient({
      link: authLink.concat(errorLink).concat(splitLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore'
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        }
      }
    });
  };

  audPrefix: string = '';

  runMutation = async (
    mutation: DocumentNode,
    variables: Record<string, any>,
    parseVariables: boolean = true,
    parseResults: boolean = true
  ): Promise<any> => {
    const parsedValues = parseVariables ? decamelizeKeys(variables) : variables;

    try {
      await this.handleToken();
      if (this.client === undefined) {
        throw new Error('this.client is undefined');
      }

      const res: FetchResult<any> = await this.client.mutate({
        mutation,
        variables: parsedValues
      });
      // eslint-disable-next-line consistent-return
      const result = parseResult(res, parseResults);
      if (result === undefined) {
        throw new Error('api error');
      }

      // eslint-disable-next-line consistent-return
      return result;
    } catch (err) {
      ErrorMonitor.captureException(err as Error);
      throw err;
    }
  };

  async handleToken(): Promise<void> {
    if (this.client === undefined) {
      throw new Error('this.client is undefined');
    }
    if (accessTokenGetter === null) {
      throw new Error('accessTokenGetter is undefined');
    }
    try {
      // AmplService.sendEvent(AmplService.EVENTS.TRACE_GETTING_TOKEN);
      const token = await accessTokenGetter();
      // AmplService.sendEvent(AmplService.EVENTS.TRACE_GET_TOKEN_SUCCESS);
      setAccessToken(token);
    } catch (err) {
      AmplService.sendEvent(AmplService.EVENTS.TRACE_GET_TOKEN_ERROR);
      console.log('get token error redirect to login', err);
      ErrorMonitor.captureException(err as Error);
      redirectToLogin();
    }
  }

  async runQuery(
    query: DocumentNode,
    variables: Record<string, any> = {},
    parseResults: boolean = true,
    logError: boolean = true
  ): Promise<any> {
    const parsedValues = decamelizeKeys(variables);

    try {
      await this.handleToken();
      if (this.client === undefined) {
        throw new Error('this.client is undefined');
      }
      const res: FetchResult<any> = await this.client.query({
        query,
        variables: parsedValues
      });

      // eslint-disable-next-line consistent-return
      const result = parseResult(res, parseResults);
      if (result === undefined) {
        console.log('api error');
        throw new Error('api error');
      }
      console.log('api result good');

      // eslint-disable-next-line consistent-return
      return result;
    } catch (err) {
      console.log('api error', err);
      if (logError) {
        ErrorMonitor.captureException(err as Error, {
          query
        });
      }
      throw err;
    }
    // eslint-disable-next-line consistent-return
  }

  runSubscription(
    query: DocumentNode,
    variables: Record<string, any>,
    onData: (data: any) => void,
    parseResults: boolean = true
  ): Subscription {
    const parsedValues = decamelizeKeys(variables);
    if (this.client === undefined) {
      throw new Error('this.client is undefined');
    }
    const sub = this.client.subscribe({
      query,
      variables: parsedValues
    });
    const activeSub = sub.subscribe({
      next: (data: FetchResult<any>) => {
        const result = parseResult(data, parseResults);
        if (result === undefined) {
          ErrorMonitor.captureException(new Error('api subscribe error'));
        } else {
          const parsedData = parseResult(data, parseResults);
          onData(parsedData);
        }
      },
      error: (error: ErrorLink.ErrorHandler) => {
        console.log('sub error');
        activeSub.unsubscribe();
        onError(error);
      }
    });

    return activeSub;
  }

  getOnt = async (url: string, version: string): Promise<IOnt> => {
    const result = await this.runQuery(api.case.queries.getOnt, {
      url,
      version
    });
    return result.ont;
  };

  // eslint-disable-next-line class-methods-use-this
  currentOntVersion = (): string => {
    const overRideCurrentOntVersion = localStorage.getItem('overRideCurrentOntVersion');
    const ver = overRideCurrentOntVersion ?? process.env.REACT_APP_ONT_VERSION;
    if (ver === undefined) {
      throw new Error('ONT_VERSION is undefined');
    }
    return ver;
  };

  getAllowedSites = async (sitesIds: string[]): Promise<ISiteData[]> => {
    const data = await this.runQuery(api.site.queries.getAllowedSites, { sitesIds });
    const sites: ISiteData[] = data.sites.map((site: ISiteRawData) => ({
      ...site,
      specialties: JSON.parse(site.specialties)
    }));

    return sites;
  };

  getAllowedUsers = async (
    sitesIds: string[],
    logError: boolean = true
  ): Promise<IFullUserData[]> => {
    const result = await this.runQuery(
      api.user.queries.getAllowedUsers,
      { sitesIds },
      true,
      logError
    );
    return result.users;
  };

  getCurrentUser = async (): Promise<IFullUserData> => {
    const data = await this.runQuery(
      api.user.queries.getCurrentUser,
      addIdFromToken({}, ['user_id'])
    );
    return data.usersByPk;
  };

  getCurrentUserMetaData = async (): Promise<IUserMetaData> => {
    const data = await this.runQuery(
      api.user.queries.getCurrentUserMetaData,
      addIdFromToken({}, ['user_id'])
    );
    return data.usersByPk;
  };

  updateCurrentUser = async (set: Record<string, any>): Promise<IFullUserData> => {
    const data = await this.runQuery(
      api.user.mutations.updateUser,
      addIdFromToken({ user: set }, ['user_id'])
    );
    return data.updateUsersByPk;
  };

  updateCurrentUserRole = async (oldRole: ROLES, newRole: ROLES): Promise<void> => {
    const data = await this.runQuery(
      api.user.mutations.updateUserRole,
      addIdFromToken({ oldRole, newRole }, ['user_id'])
    );

    if (data.deleteUserRoles.affectedRows !== 1) {
      throw new Error('failed to update role. deleteUserRoles.affectedRows is not 1');
    }
    if (!data.insertUserRolesOne?.userId) {
      throw new Error('failed to update role. insertUserRolesOne.userId is undefined');
    }

    // update the role in this.users
    const user = this.users.find(u => u.id === data.insertUserRolesOne.userId);
    if (!user) {
      throw new Error('failed to update role. user is undefined');
    }

    user.roles = user.roles.filter(r => r.role !== oldRole);
    user.roles.push({ role: newRole });
  };

  deleteCurrentUserRole = async (role: ROLES): Promise<void> => {
    const data = await this.runQuery(
      api.user.mutations.deleteUserRole,
      addIdFromToken({ role }, ['user_id'])
    );

    if (data.deleteUserRoles.affectedRows !== 1) {
      console.log('deleteUserRoles.affectedRows is not 1:', data.deleteUserRoles.affectedRows);
    }
  };

  getMetas = async (keys: string[]): Promise<IMeta[]> => {
    const data = await this.runQuery(api.site.queries.getMetas, { keys });
    return data.meta;
  };

  getCareTeamUploadcareKey = async (): Promise<string> => {
    const data = await this.runQuery(api.site.queries.getCareTeamUploadcareKey);
    return data.metaByPk.value;
  };

  getMyCases = async (logError: boolean = true): Promise<ICaseBasicData[]> => {
    const data = await this.runQuery(
      api.case.queries.getMyCases,
      addIdFromToken({}, ['user_id']),
      true,
      logError
    );
    if (data === undefined) {
      ErrorMonitor.captureException(new Error('undefined data in getMyCases'));
      return [];
    }
    return this.processBasicCases(data.cases);
  };

  getCasesByDescriptionOrDisplayId = async (
    description: string,
    displayId: string,
    siteId: string
  ): Promise<ICaseByDescriptionData[]> => {
    const data = await this.runQuery(api.case.queries.getCasesByDescriptionOrDisplayId, {
      description,
      displayId,
      siteId
    });

    return data.casesAggregate.nodes;
  };

  getFieldValuesCount = async (
    procedureId: string,
    attendingId: string,
    siteId: string
  ): Promise<IFieldValuesCountSum> => {
    const data = await this.runQuery(api.case.queries.getFieldValuesCount, {
      procedureId,
      userId: attendingId,
      siteId
    });

    // Filter out deleted field values
    // Replace value with edited value if exists
    const all = data.fieldValuesCount
      .filter((r: IRawFieldValuesCount) => !r.edits?.deleted)
      .map((r: IRawFieldValuesCount): IFieldValuesCount => {
        return {
          siteId: r.siteId,
          procedureId: r.procedureId,
          fieldId: r.fieldId,
          value: r.edits?.newValue ?? r.value,
          count: r.count
        };
      });
    const attending = data.getFieldValuesCountPerUser
      .filter((r: IRawFieldValuesCount) => !r.edits?.deleted)
      .map((r: IRawFieldValuesCount): IFieldValuesCount => {
        return {
          siteId: r.siteId,
          procedureId: r.procedureId,
          fieldId: r.fieldId,
          value: r.edits?.newValue ?? r.value,
          count: r.count
        };
      });

    return { all, attending };
  };

  getCases = async (dateGte: string): Promise<ICaseBasicData[]> => {
    const data = await this.runQuery(api.case.queries.getAll, {
      dateGte
    });
    if (data === undefined) {
      ErrorMonitor.captureException(new Error('undefined data in getCases'));
    }
    return this.processBasicCases(data.cases);
  };

  getLiteCases = async (logError = true): Promise<ICaseBasicData[]> => {
    const data = await this.runQuery(api.case.queries.getLiteCases, {}, true, logError);
    return this.processBasicCases(data.cases);
  };

  subscribeToCases = (
    isLite: boolean,
    isCurrentUserSurgeon: boolean,
    dateGte: string,
    onData: (casesData: ICaseBasicData[]) => void
  ): Subscription => {
    if (isLite && isCurrentUserSurgeon) {
      return this.runSubscription(
        api.case.subscriptions.getLiteSurgeonCasesSubscription,
        addIdFromToken({}, ['user_id']),
        data => {
          onData(this.processBasicCases(data.cases));
        }
      );
    }
    if (isLite && !isCurrentUserSurgeon) {
      return this.runSubscription(
        api.case.subscriptions.getLiteNonSurgeonCasesSubscription,
        {},
        data => {
          onData(this.processBasicCases(data.cases));
        }
      );
    }

    return this.runSubscription(api.case.subscriptions.getCasesSubscription, { dateGte }, data => {
      onData(this.processBasicCases(data.cases));
    });
  };

  subscribeToCasesUnreadComments = (
    onData: (casesToUnreadCount: Map<string, number>) => void
  ): Subscription => {
    return this.runSubscription(
      api.case.subscriptions.getCasesUnreadCommentsCountSubscription,
      addIdFromToken({}, ['user_id']),
      data => {
        const casesToUnreadCount = new Map<string, number>();
        data.cases.forEach(
          (entry: { id: string; commentsAggregate: { aggregate: { count: number } } }) => {
            casesToUnreadCount.set(entry.id, entry.commentsAggregate.aggregate.count);
          }
        );
        onData(casesToUnreadCount);
      }
    );
  };

  createCaseAsset = async (
    caseId: string,
    externalId: string,
    stage: string,
    type: string = 'image'
  ): Promise<ICaseAsset> => {
    const data = await this.runMutation(api.caseAsset.mutations.create, {
      object: {
        caseId,
        externalId,
        type,
        stage
      }
    });
    return data.insertCaseAssetsOne;
  };

  followCase = async (caseId: string, userId: string): Promise<string> => {
    return await this.runMutation(api.case.mutations.followCase, { caseId, userId });
  };

  updateLastSeen = async (caseId: string, userId: string, lastSeen: Date): Promise<string> => {
    return await this.runMutation(api.case.mutations.updateLastSeen, {
      caseId,
      userId,
      lastSeen
    });
  };

  unfollowCase = async (caseId: string, userId: string): Promise<number> => {
    return await this.runMutation(api.case.mutations.unfollowCase, { caseId, userId });
  };

  setCaseFollowers = async (
    caseId: string,
    followersIds: string[],
    followers: ICaseFollowersInput[]
  ): Promise<number> => {
    return await this.runMutation(api.case.mutations.setCaseFollowers, {
      caseId,
      followersIds,
      followers
    });
  };

  upsertPlanFeedback = async (caseId: string, rating: number, text: string): Promise<string> => {
    return await this.runMutation(api.planFeedback.mutations.upsertPlanFeedback, {
      caseId,
      rating,
      text
    });
  };

  deletePlanFeedback = async (caseId: string, userId: string): Promise<number> => {
    return await this.runMutation(api.planFeedback.mutations.deletePlanFeedback, {
      caseId,
      userId
    });
  };

  updateCaseAsset = async (id: string, set: Record<string, any>): Promise<ICaseAsset> => {
    const data = await this.runMutation(api.caseAsset.mutations.update, {
      id,
      set
    });
    return data.updateCaseAssetsByPk;
  };

  deleteCaseAsset = async (id: string): Promise<string> => {
    return await this.runMutation(api.caseAsset.mutations.delete, { id });
  };

  getUploadcareSignature = async (id: string): Promise<IUploadcareSignature> => {
    return await this.runQuery(api.caseAsset.queries.getUploadcareSignature, { caseId: id });
  };

  getCaseAssets = async (caseId: string): Promise<ICaseAsset[]> => {
    const countData = await this.runQuery(api.caseAsset.queries.getCaseAssetsCount, { caseId });
    const { count } = countData.caseAssetsAggregate.aggregate;
    if (count === 0) {
      return [];
    }
    const data = await this.runQuery(api.caseAsset.queries.getCaseAssets, {
      caseId
    });
    return data.getCaseAssets.sort((a: ICaseAsset, b: ICaseAsset) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  };

  submitCareTeamDefaults = async (
    userId: string,
    procedureId: string,
    values: Record<string, IPMMFields>
  ): Promise<void> => {
    await this.runMutation(
      api.case.mutations.submitCareTeamDefaults,
      {
        user_id: userId,
        procedure_id: procedureId,
        values,
        form: 'brief',
        user_role_in_case: USER_CASE_ROLES.ATTENDING,
        never_save_defaults: false
      },
      false
    );
  };

  submitCareTeamDefaultsStopInsights = async (
    userId: string,
    procedureId: string,
    stopInsights: Record<string, string[]>
  ): Promise<void> => {
    await this.runMutation(
      api.case.mutations.submitCareTeamDefaultsStopInsights,
      {
        user_id: userId,
        procedure_id: procedureId,
        stop_insights: stopInsights,
        form: 'brief',
        user_role_in_case: USER_CASE_ROLES.ATTENDING
      },
      false
    );
  };

  getCasesByProcedureId = async (): Promise<ICasesByProcedureData[]> => {
    const data = await this.runQuery(api.case.queries.getCasesByProcedureId);
    return data.casesByProcedureId;
  };

  getCase = async (id: string): Promise<ICaseFullData | null> => {
    const data = await this.runQuery(api.case.queries.getCase, {
      id
    });
    const c = data.casesByPk;
    if (c === null) {
      return null;
    }
    return this.parseFullCase(data.casesByPk);
  };

  getCareTeamAllDefaults = async (): Promise<ICareTeamDefaults[]> => {
    const data = await this.runQuery(
      api.case.queries.careTeamGetAllDefaults,
      { userRoleInCase: USER_CASE_ROLES.ATTENDING },
      false
    );
    const rawResults: ICareTeamRawDefaults[] = data.care_team_defaults;
    const results = rawResults.map((rawDefaults: ICareTeamRawDefaults) => {
      const {
        user_id: userId,
        procedure_id: procedureId,
        values,
        form,
        stop_insights: stopInsights
      } = rawDefaults;

      const defaults = {
        values: values || {},
        procedureId,
        form,
        userId,
        stopInsights: stopInsights || {}
      };
      return defaults;
    });
    return results;
  };

  async updateCaseValues(
    caseId: string,
    values: Record<string, any>,
    overrideDate: Date | undefined = undefined
  ): Promise<ICaseFullData> {
    const res = await this.runMutation(api.case.mutations.updateCaseValues, { caseId, values });

    if (lite && values.caseDate) {
      if (overrideDate === undefined) {
        throw new Error('overrideDate is undefined');
      }
      addCaseToMap(caseId, overrideDate);
    }

    return this.parseFullCase(res.updateCasesByPk);
  }

  submitBriefForm = async (caseId: string, pmmValues: IServerProcessedField[]): Promise<void> => {
    return await this.runMutation(api.case.mutations.submitBriefForm, {
      caseId,
      pmmValues
    });
  };

  subscribeToCaseComments = (
    caseId: string,
    onData: (comments: ICommentData[]) => void
  ): Subscription => {
    return this.runSubscription(
      api.case.subscriptions.getCaseCommentsSubscription,
      { caseId },
      data => {
        onData(data.comments);
      }
    );
  };

  clearCase = async (caseId: string): Promise<number> => {
    return await this.runMutation(api.case.mutations.clearCase, { id: caseId });
  };

  createCase = async (
    values: ICreateCaseInput,
    overrideDate: Date | undefined = undefined
  ): Promise<ICaseFullData> => {
    const result = await this.runMutation(api.case.mutations.create, {
      object: values
    });
    const c: ICaseFullData = result.insertCasesOne;
    if (lite) {
      if (overrideDate === undefined) {
        throw new Error('overrideDate is undefined');
      }
      addCaseToMap(c.id, overrideDate);
    }
    return this.parseFullCase(c);
  };

  createComment = async (caseId: string, comment: string): Promise<ICommentData> => {
    const result = await this.runMutation(api.comment.mutations.create, {
      object: {
        caseId,
        comment
      }
    });
    return result.insertCommentsOne;
  };

  updatePmmValue = async (
    caseId: string,
    fieldId: string,
    pmmValue: IServerProcessedField
  ): Promise<void> => {
    await this.runMutation(api.case.mutations.updatePmmValue, {
      caseId,
      fieldId,
      pmmValue
    });
  };

  async getIdHash(): Promise<string> {
    try {
      const result = await this.runQuery(api.user.queries.getIdHash, {});
      return result?.hashId?.hash;
    } catch (error) {
      return '';
    }
  }

  async inviteUser(newSite: boolean): Promise<string> {
    const result = await this.runMutation(api.user.mutations.inviteUser, { newSite });
    return result.insertInvitesOne.id as string;
  }

  async updateUserNickname(nickname: string): Promise<void> {
    await this.runMutation(
      api.user.mutations.updateUserNickname,
      addIdFromToken({ nickname }, ['user_id'])
    );
  }

  async upsertCommentMetaDatas(values: Array<Record<string, any>>, col: string): Promise<void> {
    await this.runMutation(api.comment.mutations.upsertCommentMetaDatas, {
      objects: values,
      col
    });
  }

  async getAttReadyCasesForTemplateRecommendations(
    userId: string,
    procedureId: string,
    limit: number
  ): Promise<ICaseFullData[]> {
    const data = await this.runQuery(api.case.queries.getAttReadyCasesForTemplateRecommendations, {
      userId,
      procedureId,
      limit
    });
    return this.processFullCases(data.cases);
  }

  async deleteCaseStateLogs(
    caseId: string,
    userId: string,
    fromState: string,
    toState: string
  ): Promise<number> {
    const data = await this.runMutation(api.case.mutations.deleteCaseStateLogs, {
      caseId,
      userId,
      fromState,
      toState
    });

    return data.affectedRows;
  }

  async changeCaseStatus(caseId: string, days: number): Promise<boolean> {
    const data = await this.runMutation(api.case.mutations.changeCaseStatus, {
      caseId,
      days
    });

    return data.success;
  }

  async checkNetworkFallback(): Promise<boolean> {
    try {
      const url = `https://${AUTH_CONFIG.audPrefix}.${API_CONFIG.graphqlUrl}`.replace(
        'v1/graphql',
        'healthz'
      );
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      console.log(error);
    }
    return false;
  }
}

const instance = new ApiService();
export default instance;
