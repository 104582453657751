import React, { useEffect, useState } from 'react';

import { useRootStore } from 'app/mobxStore';
import { observer } from 'mobx-react-lite';
import OrBoardHeader from 'app/components/orBoardHeader/OrBoardHeader';
import './Cases.scss';
import CasesList from 'app/components/orBoard/casesList/CasesList';
import Huddle from 'app/components/huddle/Huddle';
import { TabsEnum } from '../../mobxStore/types';
import { caseStateInBriefAndReview, USER_CASE_ROLES } from '../../consts';
import { getUserRoleInCase } from '../../../utils/form/shared';
import useOurSearchParams from '../../components/caseViews/hooks/useOurSearchParams';
import { toZonedTime } from 'date-fns-tz';
import { toast } from 'react-toastify';
import { useInterval } from 'usehooks-ts';
import apiService from '../../services/apiService';
import useHandleVisibilityChange from './hooks/useHandleVisibilityChange';
import useIsNeedOnboarding from './hooks/useIsNeedOnboarding';
import useIsOptIn from './hooks/useIsOptIn';
import useShowPushPopup from './hooks/useShowPushPopup';
import useTimezoneToast from './hooks/useTimezoneToast';

const Cases = (): React.JSX.Element => {
  const { caseStore, userStore, metaDataStore } = useRootStore();
  const [casesLoaded, setCasesLoaded] = useState(false);
  const [shortListLoaded, setShortListLoaded] = useState(false);
  const { ourSearchParams, setOurSearchParams } = useOurSearchParams();

  useIsNeedOnboarding();
  useIsOptIn();
  useShowPushPopup();
  useTimezoneToast();
  useHandleVisibilityChange();

  useInterval(async () => {
    try {
      await metaDataStore.loadUsersList(false);
      if (!apiService.missingUser) {
        return;
      }
      await caseStore.loadAllCases(true, false);
      apiService.missingUser = false;
    } catch (error) {
      console.error('Error in interval', error);
    }
  }, 1000 * 30);

  const initDateSearchParam = (reset: boolean = false): void => {
    if (ourSearchParams.date !== undefined) {
      return;
    }
    const today = toZonedTime(new Date(), metaDataStore.tz);
    setOurSearchParams(
      {
        date: today
      },
      reset
    );
  };

  const handleParams = async (): Promise<void> => {
    if (ourSearchParams.caseId !== undefined) {
      const paramCaseId = ourSearchParams.caseId;
      if (caseStore.openedCase?.basicCase?.id !== paramCaseId) {
        const caseData = await caseStore.openCase(paramCaseId);
        if (caseData === null || !caseStore.openedCase) {
          initDateSearchParam(true);
          toast.warning('Case was deleted or canceled', {
            toastId: 'Case was deleted or canceled'
          });
          return;
        }

        const userRoleInCase = getUserRoleInCase(
          caseStore.openedCase.basicCase.data,
          userStore.loggedInUser.data
        );
        if (userRoleInCase === USER_CASE_ROLES.NONE && caseStateInBriefAndReview(caseData.state)) {
          setOurSearchParams(
            {
              tab: TabsEnum.CHAT
            },
            false
          );
        }
      }
    }

    if (ourSearchParams.tab !== undefined) {
      caseStore.setSelectedTab(ourSearchParams.tab);
    }

    if (ourSearchParams.date !== undefined) {
      if (caseStore.selectedDateFilter.valueOf() === ourSearchParams.date.valueOf()) {
        return;
      }
      caseStore.setSelectedDateFilter(ourSearchParams.date);
    }
  };

  useEffect(() => {
    const hp = async (): Promise<void> => {
      await handleParams();
    };
    void hp();
  }, [ourSearchParams]);

  useEffect(() => {
    const loadShortList = async (): Promise<void> => {
      await caseStore.loadAllCases(true);
      setCasesLoaded(true);
      setShortListLoaded(true);
    };
    void loadShortList();
    initDateSearchParam();
  }, []);

  useEffect(() => {
    if (!shortListLoaded) {
      return;
    }
    const subscribeToCases = async (): Promise<void> => {
      await caseStore.subscribeToCases();
    };
    void subscribeToCases();
  }, [shortListLoaded]);

  return (
    <div className="page-container">
      <OrBoardHeader />
      <div className="cases-page" id="cases-page">
        <Huddle />
        {casesLoaded && <CasesList />}
      </div>
    </div>
  );
};

export default observer(Cases);
