const locale = {
  chiefy: 'Chiefy',
  myCases: 'My cases',
  allCases: 'All cases',
  orBoard: 'OR Board',
  join: 'Follow',
  leave: 'Unfollow',
  editHuddleTeam: 'Edit huddle team',
  assignTeamMembers: 'Assign team members',
  huddleButton: 'Huddle',
  standardProcButton: 'Standard procedure',
  imri: 'iMRI',
  pending: 'Pending',
  huddled: 'Huddled',
  postponed: 'Rescheduled',
  cancelled: 'Canceled',
  standardProcedureChip: 'Standard procedure',
  sentToSurgeon: 'Sent to surgeon',
  delete: 'Delete',
  edit: 'Edit',
  approve: 'Share with team',
  approveAndOpenNext: 'Share with team & \nopen next',
  send: 'Send',
  sendToSurgeon: 'Send to surgeon',
  save: 'Save',
  saveAndApply: 'Save & apply',
  view: 'View',
  done: 'Done',
  apply: 'Apply',
  huddle: 'Huddle',
  inviteAgain: 'Invite again',
  restoreData: 'Restore data',
  notify: 'Notify',
  you: 'you',
  create: 'Create',
  back: 'Back',
  getStarted: 'Get started',
  role: 'Role',
  searchTeamMembers: 'Search team members',
  searchProcedure: 'Search template',
  searchService: 'Search service',
  surgeon: 'Surgeon',
  assistant: 'Assistant',
  service: 'Service',
  date: 'Date',
  pickDate: 'Pick a date',
  letsHuddle: "Let's huddle",
  writeATitle: 'Describe the procedure...',
  selectProcedure: 'Select procedure...',
  selectFromAList: 'Select from a list...',
  description: 'Description',
  required: 'Required',
  discardPanelTitle: 'Are you sure you want to discard the changes?',
  discard: 'Discard changes',
  keepEditing: 'Keep editing',
  imageDeletePanelTitle: 'Are you sure you want to delete this image?',
  imageDeletePanelYes: 'Yes',
  caseDeletePanelTitle: 'Are you sure you want to delete this case?',
  caseDeletePanelYes: 'Yes',
  casePostponed: 'Case rescheduled',
  casePostponePanelTitle: 'Reschedule to {{date}}?',
  casePostponePanelTextWithNotify:
    'We will notify team members who follow the huddle that the case was rescheduled, and update the huddle.',
  casePostponePanelTextWithoutNotify: 'We will update the huddle.',
  casePostponePanelYesWithNotify: 'Yes, reschedule and notify',
  casePostponePanelYesWithoutNotify: 'Yes, reschedule case',
  postponeDatePickerTitle: 'What is the new date?',
  caseCancelled: 'Case canceled',
  caseCancelPanelTitle: 'Are you sure the case was canceled?',
  caseCancelPanelTextWithNotify:
    'We will notify team members who follow the huddle that the case was canceled, and remove the huddle.',
  caseCancelPanelTextWithoutNotify: 'We will remove the huddle.',
  caseCancelButtonOKWithNotify: 'Yes, cancel and notify',
  caseCancelButtonOKWithoutNotify: 'Yes, case was canceled',
  followersDiscardTitle:
    'You changed the case followers without saving. \nAre you sure you want to discard?',
  followersDiscardConfirm: 'Yes, discard changes',
  followersDiscardBack: 'No, back to edit',
  images: 'Images',
  addYourOwn: 'Add your own',
  selectTemplate: 'Select a template for this huddle:',
  searchTemplates: 'Search all templates',
  topSuggestions: 'Top suggestions for you:',
  suggetionsForYou: 'Suggestions for you',
  searchResults: 'Search results',
  askAQuestion: 'Message',
  errorTitle: 'Something went wrong',
  errorSubtitle: 'Please go back or report an issue.',
  offlineTitle1: 'No internet connection',
  offlineTitle2: 'Trying to reconnect...',
  offlineSubtitle: 'Check your wifi or data connection',
  returnToBoard: 'Back',
  reportIssue: 'Report an issue',
  reportIssueEmailUrl:
    'mailto:hello@chiefyteam.com?Subject=Something is not working for me 🙀. Here are the details.',
  today: 'Today',
  upcoming: 'Upcoming',
  current: 'Current',
  myAccount: 'My account',
  logOut: 'Log out',
  contactUs: 'Contact us',
  newSiteInvite: 'Invite a friend to create their own team',
  enablePush: 'Enable notifications',
  accountSettings: 'Your profile',
  firstName: 'First name',
  lastName: 'Last name',
  nickName: 'Display name',
  nickNamePlaceholder: '',
  email: 'Email address',
  phone: 'Phone number',
  phonePlaceholder: '555-555-5555',
  invalidPhoneNumber: 'Phone number should be 10-digits long, or empty',
  saveChanges: 'Save changes',
  nursingFeedbackTitle: 'Keep learning. Keep improving.',
  nursingSubtitleEmpty: 'Feedback on this huddle?',
  feedbackInputLabel: 'What went well? What could go better?',
  feedbackInputPlaceHolder: '...',
  lite: 'LITE',
  seenTooltipText: 'The <eyeIcon /> icon shows <bold>who viewed the huddle</bold>',
  unfollowTooltipText:
    'Click the ’Unfollow’ button to <bold>stop receiving notifications for this case</bold>',
  chatTabTooltipTextSurgeon: 'Something to add? \nToggle to the huddle chat',
  chatTabTooltipTextNonSurgeon:
    'Questions to {{ attending }}? Something to add? \nToggle to the huddle chat',
  comingSoon: 'Coming soon!',
  caseNumber: 'Case #',
  room: 'Room',

  // Empty cases list
  noCasesYet: 'No cases yet.',
  emptyCasesTitle1: "We'll notify when you have new cases.",
  emptyCasesTitle2:
    "Once you see cases in the list, join them and we'll notify about the plan and any changes.",
  emptyCaseText2: 'Don\'t see your case, or need to huddle for an add-on? Click on "+" to add',

  emptyCaseQuote1: '“Quality means doing it right when no one is looking.”',
  emptyCaseQuoteBy1: '~ Henry Ford',
  emptyCaseQuote2: '“If everyone is moving forward together, then success takes care of itself.”',
  emptyCaseQuoteBy2: '~ Henry Ford',
  emptyCaseQuote3: '“Talent wins games, but teamwork and intelligence win championships."',
  emptyCaseQuoteBy3: '~ Michael Jordan',
  emptyCaseQuote4:
    '“Coming together is a beginning. Keeping together is progress. Working together is success.”',
  emptyCaseQuoteBy4: '~ Henry Ford',
  emptyCaseQuote5: '“Quality is not act. It is a habit.”',
  emptyCaseQuoteBy5: '~ Aristotle',
  emptyCaseQuote6: '“If you can’t explain it simply, you don’t understand it well enough.”',
  emptyCaseQuoteBy6: '~ Albert Einstein',
  emptyCaseQuote7: '“Success is the sum of small efforts, repeated day-in and day-out.”',
  emptyCaseQuoteBy7: '~ Robert Collier',
  emptyCaseQuote8: 'Quality is never an accident. It is always the result of intelligent effort.”',
  emptyCaseQuoteBy8: '~ John Ruskin',
  emptyCaseQuote9:
    '“Unity is strength... when there is teamwork and collaboration, wonderful things can be achieved.”',
  emptyCaseQuoteBy9: '~ Mattie Stepanek',
  emptyCaseQuote10: '“No one can whistle a symphony. It takes a whole orchestra to play it.”',
  emptyCaseQuoteBy10: '~ H.E. Luccock',
  emptyCaseQuote11:
    '“There is immense power when a group of people with similar interests gets together to work toward the same goals.”',
  emptyCaseQuoteBy11: '~ Idowu Koyenikan',
  emptyCaseQuote12:
    '“Cooperation is the thorough conviction that nobody can get there unless everybody gets there.”',
  emptyCaseQuoteBy12: '~ Virginia Burden',
  emptyCaseQuote13:
    '"Individual commitment to a group effort - that is what makes a team work, a company work, a society work, a civilization work."',
  emptyCaseQuoteBy13: '~ Vince Lombardi',
  emptyCaseQuote14:
    '“A team is not a group of people who work together. A team is a group of people who trust each other.”',
  emptyCaseQuoteBy14: '~ Simon Sinek',

  emptyCaseGotHuddlesTomorrow: 'Got huddles tomorrow',
  emptyCaseGotHuddlesOn: 'Got huddles on {{ to }}',
  emptyCaseGotHuddlesNextWeek: 'Got huddles next {{ to }}',
  emptyStateNoHuddlesSoon: 'No huddles soon!',
  emptyStateNoHuddlesHere: 'No huddles here!',
  emptyStateHuddlesUpcoming: 'Got upcoming huddles!',
  emptyStateTakeMeThere: 'Take me there',

  goToChat: 'Go to chat',
  hasSavedPreferenceMine: 'Huddle in seconds using your preferences',
  hasSavedPreferenceNotMine: "Huddle in seconds using {{ attending }}'s preferences",
  noSavedPreference: 'Set the preferences for {{procedure}}, and fill future huddles in seconds',
  useMyPreferences: 'Use my preferences',
  undo: 'Undo',
  useNotMyPreferences: 'Use preferences',
  editPreferences: 'Edit preferences',
  editAttPreferences: "Edit {{ attending }}'s preferences",
  waitingForHuddleTitle: `{{ attending }} hasn't huddled yet.`,
  waitingForHuddleSubtitle: `Start the conversation in the chat!`,
  waitingForHuddleChatTitle: `Start the conversation.`,
  waitingForHuddleChatSubtitle: `You can ask questions or add information for the team.`,
  standardProcTitle: '{{ attending }} marked this as a standard procedure',
  standardProcSubtitleAttending: 'Not standard? Huddle now.',
  standardProcSubtitleNonAttending: 'You can ask questions in the chat.',

  procedureChangeConfirmationTitle:
    'This will override your current huddle. Images and chat that will be kept.',
  procedureChangeConfirmationYes: 'Change template',
  procedureChangeConfirmationNo: 'Back',

  dailyStatsDone: 'Hurray! 100% Huddled',
  dailyStatsEmpty: 'You have no huddles for this day',
  // dailyStatsNotDonePlural: 'Huddle up! {{todoCount}} out of {{totalCount}} cases are waiting',
  dailyStatsNotDonePlural:
    'Huddle up! You’ve huddled <bold>{{doneCount}} / {{totalCount}}</bold> cases',
  dailyStatsNotDoneSingle: 'Huddle up! Only one case is waiting',
  dailyStatsNotDonePast: '<bold>{{doneCount}} / {{totalCount}}</bold> cases were huddled',
  dailyStatsOnlyUser: 'Team up! Add more members to your team',

  recommendationModalTitle: 'New insight',
  recommendationModalTextAddYou:
    'You usually select <italic>{{recommendation}}</italic> in this procedure. Next time we will suggest it automatically.',
  recommendationModalTextRemoveYou:
    'You usually deselect <italic>{{recommendation}}</italic> in this procedure. Next time we will not suggest it automatically.',
  recommendationModalTextAddSurgeonName:
    '{{attending}} usually selects <italic>{{recommendation}}</italic> in this procedure. Next time we will suggest it automatically.',
  recommendationModalTextRemoveSurgeonName:
    '{{attending}} usually deselects <italic>{{recommendation}}</italic> in this procedure. Next time we will not suggest it automatically.',
  great: 'Great!',

  inviteUser: 'Invite user',
  inviteTeam: 'Invite team',
  inviteDialogTitle: 'Invite your team',
  inviteDialogSubtitle:
    'Invite others to your Chiefy Lite account to give them access to your huddles.',
  createInvite: 'Create an invite',
  inviteDisclaimer:
    'Each team member requires a new invitation. This invitation is valid for 24 hours.',
  newSiteInviteDialogSubtitle:
    'Invite others to create a new Chiefy Lite account. Information from your account is not shared with them.',
  newSiteInviteDisclaimer:
    'Each friend requires a new invitation. This invitation is valid for 24 hours.',
  share: 'Share',
  copy: 'Copy',
  anotherInvite: 'Create another invite',
  inviteCopied: 'Copied to clipboard',
  inviteTitle: 'Invite to Chiefy',
  inviteContentNewSite:
    'Hi, I have been using the Chiefy app to huddle with my team before cases, and I thought you may like it too.\n' +
    '\n' +
    'It’s super simple, user-friendly, and saves us a lot of time.\n' +
    '\n' +
    "👇 You can create an account in their non-PHI Lite version and try it with your team. Here's an invite link (it takes less then 1 minute).\n" +
    '\n' +
    '{{url}}',
  inviteContentExistingTeam:
    'Hi, please meet the Chiefy app.\n' +
    '\n' +
    'From now on, I will share information with the team here before each case, and you can ask me any questions here.\n' +
    'It’s super simple, user-friendly, and will save us a lot of time.\n' +
    '\n' +
    "👇 Please sign up below (it takes less then 1 minute!), and I'll be notified when you join.\n" +
    '\n' +
    '{{url}}',
  qrExpirationText: 'Valid until {{expirationTime}} for one team member.',
  privacy: 'Privacy Policy',
  terms: 'Terms of Use',
  version: 'Version',
  mailConfirmationText: 'We’ve sent a confirmation email to {{address}}.',
  checkInbox: 'Check your inbox.',
  simplyAlign: 'Simply align',
  addToHomeSteps: 'Get the Chiefy app on your phone in 2 steps:',
  addToHomeStep1:
    '<bold>Find the</bold> <share /> <bold>button</bold> in this browser to open the Share menu.',
  addToHomeStep2: 'Click <bold>Add to Home Screen</bold> in the Share menu.',
  launchChiefy: 'Launch <bold>Chiefy</bold> from your home screen',
  terms_of_use_url: 'https://www.chiefyteam.com/terms-of-use',
  privacy_policy_url: 'https://www.chiefyteam.com/privacy-policy',
  acceptRecommendation: 'Great!',
  declineRecommendation: 'No, thanks',
  onboarding1Title: 'Welcome to Chiefy',
  onboarding2Title: '100% team alignment',
  onboarding3Title: 'Without the pain',
  onboardingWhatsNewTitle: 'Chiefy has a new look',
  onboardingWhatsNewText:
    'It’s a glow up ✨ Chiefy has a new logo and we updated the interface to reflect the new branding. ',
  onboardingWhatsNewContact: 'If you need help contact us <contact>hello@chiefyteam.com</contact>',
  onboarding1Text:
    'We help surgeons, anesthesiologists, nurses and other team members easily get on the same page before procedures and continuously align.',
  onboarding2Text:
    'We harness AI, data and gamification to make team communication and coordination easy and asynchronous.',
  onboarding3Text:
    '<bullet>Under 1 minute to huddle.</bullet> \n<bullet>Everyone is updated.</bullet> \n<bullet>Less last-minute changes. Less interruptions.</bullet> \n<bullet>Less time wasted and delays.</bullet> \n<bullet>Better care for your patients.</bullet> \n<bullet>Happier team may occur.</bullet>',
  onboardingProfileTitle: 'It’s always nice to see a familiar face',
  onboardingProfileText: 'This is what the team sees',
  saveProfilePic: 'Save profile pic',
  uploadPhoto: 'Upload photo',
  maybeLater: 'Maybe later',
  updateProfilePicture: 'Update profile picture',
  validateDisplayNameTooShort: '* Would you spare a few more letters?',
  validateDisplayNameExists: '* What are the odds? This name is already taken',
  unreadMessages: 'Unread messages',
  will_notify_new_case_now: 'We will notify {{surgeonName}} about the new huddle.',
  will_notify_new_case_later_less_then_day:
    'We will notify {{surgeonName}} about the new huddle at {{when}}.',
  will_notify_new_case_later_more_then_day:
    'We will notify {{surgeonName}} about the new huddle on {{when}}.',
  will_notify_chat_msg_later_less_then_day: 'We will notify the team at {{when}}.',
  will_notify_chat_msg_later_more_then_day: 'We will notify the team on {{when}}.',

  case_deleted: 'Case deleted',
  case_not_deleted: 'Case was not deleted',
  case_postponed: 'Case rescheduled',
  case_not_postponed: 'Case was not rescheduled',
  case_canceled: 'Case canceled',
  case_not_cancelled: 'Case was not canceled',

  different_timezone:
    "Chiefy is showing {{ siteTz }} time, and you're device is set to {{ deviceTz }}",

  case_description_hint: 'One liner of the plan (e.g. pathology, location, levels)',
  comments_to_nursing_hint:
    'For example:\n- Have the bone scalpel on standby\n- Drill pedal on the left, bipolar on the right\n- Two step stools on each side',
  comments_to_anesthesia_hint:
    'For example:\n- Awake fiberoptic intubation\n- Keep MAP > 70 throughout the case\n- Pre-flip baseline neuromonitoring',
  estimated_case_length_hint: 'Skin-to-skin',
  second_part_of_the_case_hint: 'For example: change OR table, equipment, position...',

  sendDebugLogs: 'Send debug logs',
  logsSentToast: 'Logs sent',
  devModeEnabled: 'Developer mode enabled 🐧❤️',
  devModeDisabled: 'Developer mode disabled 🐧❤️',

  caseSubmitEmptyPanelTitle: 'Your huddle is empty. Approve anyway?',
  caseSubmitEmptyPanelYes: 'Yes, approve as is',
  caseSubmitEmptyPanelNo: 'No, back to edit',
  saving: 'Saving…',
  saved: 'Draft saved',
  savedWithUpdate: 'Saved. We will let the team know.',
  updated: 'Updated',

  inviteToHuddlePanelTitle: 'Want to share your huddles with team members?',
  inviteToHuddlePanelButtonLater: "I'll do it later",
  inviteToHuddlePanelButtonNo: 'No, thanks',
  inviteToHuddlePanelButtonYes: 'Invite team members now',
  inviteToHuddlePanelToast: "We'll ask again later",

  vendor_rep_added_to_huddle: 'Vendor rep {{name}} was added to this huddle',
  vendor_rep_removed_from_huddle: 'Vendor rep {{name}} was removed from this huddle'
};

export default locale;
