import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'app//components/buttons/Button';
import { ReactComponent as PenguinFishing } from 'app/assets/penguins/penguin_fishing_animated.svg';
import { ReactComponent as PenguinSendingAirplane } from 'app/assets/penguins/penguin_sending_letter_empty_state_huddle_chat.svg';
import './HuddleMessage.scss';
import { type ICaseBasicData, TabsEnum } from '../../mobxStore/types';
import { CASE_STATE, USER_CASE_ROLES } from '../../consts';
import { useRootStore } from '../../mobxStore';
import type { TFunction } from 'i18next';
import useOurSearchParams from '../caseViews/hooks/useOurSearchParams';

const getImageAndTexts = (
  standardProc: boolean,
  isChat: boolean,
  isAttending: boolean,
  caseData: ICaseBasicData,
  t: TFunction<'translation', undefined>
): {
  title: string;
  subtitle: string;
  imageComponent: React.JSX.Element;
} => {
  const name = isAttending ? 'You' : caseData.attending?.nickName;

  if (standardProc) {
    if (isChat) {
      if (isAttending) {
        /* standardProc, isChat, isAttending */
        return {
          title: t('waitingForHuddleChatTitle'),
          subtitle: t('waitingForHuddleChatSubtitle'),
          imageComponent: <PenguinSendingAirplane />
        };
      } else {
        /* standardProc, isChat, !isAttending */
        return {
          title: t('standardProcTitle', { attending: name }),
          subtitle: t('waitingForHuddleChatSubtitle'),
          imageComponent: <PenguinSendingAirplane />
        };
      }
    } else {
      /* standardProc, !isChat */
      if (isAttending) {
        /* standardProc, !isChat, isAttending */
        return {
          title: t('standardProcTitle', { attending: name }),
          subtitle: t('standardProcSubtitleAttending'),
          imageComponent: <></>
        };
      } else {
        /* standardProc, !isChat, !isAttending */
        return {
          title: t('standardProcTitle', { attending: name }),
          subtitle: t('standardProcSubtitleNonAttending'),
          imageComponent: <></>
        };
      }
    }
  } else {
    /* !standardProc */
    if (isChat) {
      /* !standardProc, isChat */
      return {
        title: t('waitingForHuddleChatTitle'),
        subtitle: t('waitingForHuddleChatSubtitle'),
        imageComponent: <PenguinSendingAirplane />
      };
    } else {
      /* !standardProc, !isChat */
      return {
        title: t('waitingForHuddleTitle', { attending: name }),
        subtitle: t('waitingForHuddleSubtitle'),
        imageComponent: <PenguinFishing />
      };
    }
  }
};

const HuddleMessage = (): React.JSX.Element => {
  const {
    caseStore: { openedCase, selectedTabInHuddle },
    userRoleInCase
  } = useRootStore();
  const { t } = useTranslation();
  const { setOurSearchParams } = useOurSearchParams();
  const caseData = openedCase?.basicCase?.data;
  if (!caseData) {
    return <></>;
  }
  const standardProc = caseData.state === CASE_STATE.READY && caseData.standardProcedure;
  const isAttending = userRoleInCase === USER_CASE_ROLES.ATTENDING;
  const isChat = selectedTabInHuddle === TabsEnum.CHAT;

  const onGotoChatClick = async (): Promise<void> => {
    setOurSearchParams({ tab: TabsEnum.CHAT }, false);
  };

  const onHuddleNowClick = async (): Promise<void> => {
    void openedCase?.basicCase.setStandardProcedureToServer(false);
    openedCase?.basicCase.setStandardProcedureToStore(false);
  };

  const { title, subtitle, imageComponent } = getImageAndTexts(
    standardProc,
    isChat,
    isAttending,
    caseData,
    t
  );
  const goToChat = !isChat && !(standardProc && isAttending);
  const huddleNow = !isChat && standardProc && isAttending;

  return (
    <div className="empty-huddle">
      {imageComponent}
      <div className="content">
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
        {goToChat && (
          <Button
            onClick={onGotoChatClick}
            classNames="primary chat-button"
            buttonText={t('goToChat').toString()}
          />
        )}
        {huddleNow && (
          <Button
            onClick={onHuddleNowClick}
            classNames="primary chat-button"
            buttonText={t('huddleButton').toString()}
          />
        )}
      </div>
    </div>
  );
};

export default HuddleMessage;
