import { useEffect } from 'react';
import { useRootStore } from 'app/mobxStore';
import { isInstalled } from 'app/mobxStore/caseStoreHelper';
import ffService from 'app/services/ffService/ffService';
import oneSignalService from 'app/services/oneSignalService';

const LOCAL_STORAGE_KEY_PN_SHOWN = 'pnPopupShownNew2';

const useShowPushPopup = (): void => {
  const { userStore } = useRootStore();

  useEffect(() => {
    if (!isInstalled() || !ffService.isFlagOnPushNotifications()) {
      return;
    }
    const pnPopupShown = localStorage.getItem(LOCAL_STORAGE_KEY_PN_SHOWN);
    if (pnPopupShown) {
      return;
    }

    setTimeout(async () => {
      const success = await oneSignalService.showOneSignalPopup(
        userStore.loggedInUser.data.id,
        userStore.loggedInUser.data.email,
        false
      );
      if (!success) {
        return;
      }
      localStorage.setItem(LOCAL_STORAGE_KEY_PN_SHOWN, 'true');
    }, 5000);
  }, []);
};

export default useShowPushPopup;
