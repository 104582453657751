import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPassedOnboarding } from 'app/mobxStore/storage';

const useIsNeedOnboarding = (): void => {
  const navigate = useNavigate();

  useEffect(() => {
    const needOnboarding = !getPassedOnboarding();
    if (needOnboarding) {
      navigate('/onboarding');
    }
  }, []);
};

export default useIsNeedOnboarding;
